import { mapGetters } from 'vuex';

export default {
  name: 'TemplateHotelInfo',
  props: {
    hotel: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      query: this.$route.query,
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      destinationList: 'GET_DOMESTIC_DESTINATION',
      moreHotelInfo: 'GET_ONE_HOTEL_INFO',
    }),
    destinationCityName() {
      const filterArray = this.destinationList.find((item) => item.code === this.query.city);
      return (filterArray) ? filterArray.name : '';
    },
    nameHotel() {
      return this.moreHotelInfo?.hotelConversion?.hotelName ?? '';
    },
  },
};
