<template>
  <div class="heading">
    <h1>{{ destinationCityName }} - {{ nameHotel }}</h1>
    <p><img src="assets/img/theme1/location.png" alt="location" title="" />{{ nameHotel }}, {{ destinationCityName }}, Israel</p>
  </div>
</template>

<script>
import TemplateHotelInfo from './TemplateHotelInfo';

export default {
  name: 'HotelInfoThem1',
  mixins: [TemplateHotelInfo],
};
</script>

<style lang="scss" scoped>
  .product-main-area .heading{
    background-color: #E2F8FC;
    padding: 20px;
    margin: 0 0 25px;
    border-radius: 5px;
  }
  .product-main-area .heading h1{
      margin: 0 0 5px;
      color: #000000;
      font-size: 30px;
      font-weight: 500;
  }
  .product-main-area .heading p{
      margin: 0 0 0;
      color: #000000;
      font-size: 17px;
      font-weight: 400;
      display: flex;
      align-items: center;
  }
  .product-main-area .heading p strong{
      font-weight: 500;
  }
  .product-main-area .heading p img{
      margin-left: 8px;
  }

  @media(max-width: 1399px) {
    .product-main-area .heading h1 {
        font-size: 28px;
    }
  }
  @media (max-width: 1199px){

  }
  @media (max-width:767.98px){
    .product-main-area .heading h1 {
        font-size: 20px;
    }
    .product-main-area .heading p {
        font-size: 14px;
    }
  }
</style>
